





































































import {
  defineComponent,
} from '@nuxtjs/composition-api';
import SkeletonLoader from 'components/theme/SkeletonLoader.vue';

export default defineComponent({
  name: 'ProductSkeleton',
  components: {
    SkeletonLoader,
  },
});
