















































import {
  defineComponent,
  computed,
  onMounted,
  ref,
  useContext
} from '@nuxtjs/composition-api';
import { useWishlist, useWishlistStore } from '@wemade-vsf/wishlist';
import type { WishlistItemInterface } from '@wemade-vsf/magento-api';
import { SfHeading, SfBreadcrumbs, SfButton } from '@storefront-ui/vue';
import ProductSkeleton from 'components/catalog/Product/ProductSkeleton.vue';
import ProductCard from 'components/catalog/Product/ProductCard.vue';
import type { Product } from '@wemade-vsf/catalog/lib/runtime/product/types';

interface WishlistItem extends WishlistItemInterface{
  product: Product
}

export default defineComponent({
  name: 'Wishlist',
  components: {
    SfHeading,
    SfBreadcrumbs,
    SfButton,
    ProductCard,
    ProductSkeleton
  },
  setup () {
    const { i18n, localeRoute } = useContext();
    const { load, removeItem } = useWishlist();
    const wishlistStore = useWishlistStore();
    const wishlistItems = computed<WishlistItem[]>(
      () => wishlistStore.wishlist?.items_v2?.items ?? []
    )
    const breadcrumbs = ref<Array<{ text: string, link: string }>>([
      { text: i18n.t('Home'), link: localeRoute({ name: 'home' }).fullPath },
      { text: i18n.t('Wishlist'), link: localeRoute({ name: 'wishlist' }).fullPath }
    ])
    const loading = ref(false)

    async function removeFromWishlist(product) {
      await removeItem({ product })
    }

    onMounted(async () => {
      loading.value = true
      await load({ searchParams: { pageSize: 100 } })
      loading.value = false
    })

    function print ()  {
      if (typeof window !== undefined) {
        window.print()
      }
    }

    return {
      breadcrumbs,
      loading,
      print,
      removeFromWishlist,
      wishlistItems
    }
  },
  head () {
    return {
      title: this.$t('Wishlist')
    }
  }
})
